import React, { useEffect, useState } from "react";
import Map from "../components/Map";
import NavigateSection from "../components/NavigateSection";
import NavigateFrame from "../components/NavigateFrame";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const { title, subtitleInner } = t("main.contactUs.contactUsText");
  const { address, open, close, contactTitle } = t(
    "main.contactUs.contactUsText.contactInfo",
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hours = moment().hour() + 1;
    const isWithinTimeRange = hours >= 10 && hours <= 22;
    setIsOpen(isWithinTimeRange);
  }, []);

  return (
    <section className="section_padding">
      <NavigateSection to={"/contact"} header={title} link={title} />
      <NavigateFrame title={contactTitle} />
      <div className="contact_info">
        <div className="contact_title">
          <div className="contact_list">
            <div className="contact_address">
              <h4>Shine Flowers & Events</h4>
              <p>{address}</p>
              <a href="tel:+37496377211">+374 96 377 211</a>
              {isOpen ? (
                <h3 className="contact_open">{open} 10։00-22։00</h3>
              ) : (
                <h3 className="contact_closed">{close}</h3>
              )}
            </div>
          </div>
          <p className="contact_text">{subtitleInner}</p>
        </div>
        <div className="contact_map">
          <Map />
        </div>
      </div>
    </section>
  );
};

export default Contact;
