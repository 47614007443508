import React from "react";
import {NavLink} from "react-router-dom";
import logo from "../assets/pic/logo.png";
import {menuList} from "../utils/menuList";
import MainIcons from "../components/MainIcons";
import BurgerMenu from "../components/BurgerMenu";
import Languages from "../components/Languages";
import {useTranslation} from "react-i18next";

const Header = () => {
  const {t} = useTranslation();

  return (
    <header>
      <div className="logo">
        <NavLink to="/" className="logo_Items">
          <img src={logo} alt="logo" className="logo"/>
          <h5 className='logo_text'>Shine Flowers <br/>& Event</h5>
        </NavLink>
      </div>
      <div className="header_menu">
        <Languages/>
        <div className="main_menu">
          <main>
            {menuList.map((link, index) => (
              <NavLink
                key={link.id}
                to={link.to}
                className={({isActive}) => (isActive ? "active" : "link")}
              >
                {t('main.header_menu')[index]}
              </NavLink>
            ))}
          </main>
          <MainIcons/>
          <BurgerMenu/>

        </div>
      </div>
    </header>
  )
}

export default Header;
