import { v4 as uuidv4 } from "uuid";

import image1 from "../assets/pic/slideimg1.jpg";

import partnerlogo1 from "../assets/pic/partnerlogo1.png";
import partnerlogo2 from "../assets/pic/partnerlogo2.png";
import partnerlogo3 from "../assets/pic/partnerlogo3.png";
import partnerlogo4 from "../assets/pic/partnerlogo4.png";
import partnerlogo5 from "../assets/pic/partnerlogo5.png";
import partnerlogo6 from "../assets/pic/partnerlogo6.png";

export const imagesList = [
  {
    id: uuidv4(),
    src: image1,
    alt: "Image 1",
    title: "Shine Flowers ",
    subtitle: "& Events",
    // text: "Decorate your life with us",
  },
  // {
  //   id: uuidv4(),
  //   src: image2,
  //   alt: "Image 1",
  //   title: "Shine Flowers ",
  //   subtitle: "& Events",
  //   // text: "blya bvgggllya",
  // },
];

export const ourPartnersImg = [
  {
    id: uuidv4(),
    src: partnerlogo1,
    alt: "Image 2",
  },
  {
    id: uuidv4(),
    src: partnerlogo2,
    alt: "Image 2",
  },

  {
    id: uuidv4(),
    src: partnerlogo3,
    alt: "Image 2",
  },
  {
    id: uuidv4(),
    src: partnerlogo4,
    alt: "Image 2",
  },
  {
    id: uuidv4(),
    src: partnerlogo5,
    alt: "Image 2",
  },
  {
    id: uuidv4(),
    src: partnerlogo6,
    alt: "Image 2",
  },
];
